import React, { FC } from 'react';
import { IImageCardProps } from '@shared/types';
import GatsbyImage from 'gatsby-theme-husky/src/common/GatsbyImage';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/common/DangerouslySetInnerHtml';
import IconCustom from 'gatsby-theme-husky/src/common/IconCustom';
import Button from 'gatsby-theme-husky/src/common/Button';

import 'gatsby-theme-husky/src/common/ImageCard/ImageCard.scss';
import './ImageCardOverride.scss';

const ImageCard: FC<IImageCardProps> = ({
  description,
  image,
  link,
  isImageLink,
  isIconLink,
  tabIndex = 0,
}) => {
  const previewImage = image?.[0]?.properties?.image;
  const imageAlt = image?.[0]?.properties?.imageAlt;

  return (
    <div className="image-card">
      {previewImage && (
        <div className="image-card__image-container">
          {link?.[0] && isImageLink ? (
            <Button
              to={link[0].url}
              ariaLabel={link[0].name}
              target={link[0].target}
              variant="link"
            >
              <GatsbyImage image={previewImage} objectFit="contain" alt={imageAlt} />
            </Button>
          ) : (
            <GatsbyImage image={previewImage} objectFit="contain" alt={imageAlt} />
          )}
        </div>
      )}
      <DangerouslySetInnerHtml className="image-card__description" html={description} />
      {!isImageLink && link?.length
        ? link.map((item) => (
            <Button
              classes="image-card__link"
              to={item.url}
              target={item.target}
              variant="secondary-link"
              tabIndex={tabIndex}
              ariaLabel={`${item.name} about ${imageAlt} ${item.target === '_blank' ? 'opens in a new tab' : ''}`}
            >
              {item.name}
              {isIconLink ? <IconCustom icon="chevron-right" /> : null}
            </Button>
          ))
        : null}
    </div>
  );
};

export default ImageCard;